import Typography from '@mui/material/Typography';
import { IronSessionData } from 'iron-session';
import { withSession } from 'lib/session/withSession';
import type { GetServerSideProps, NextPage } from 'next';

const IndexPage: NextPage<IronSessionData> = () => {
  return (
    <div className="flex flex-col items-center content-center relative full-w">
      <Typography variant="h1" color="white">
        Home
      </Typography>
    </div>
  );
};

export default IndexPage;

export const getServerSideProps: GetServerSideProps = withSession(async function ({
  req,
}) {
  let user = null;

  if (req.session.user) {
    user = req.session.user;
  }

  return {
    props: { user },
  };
});
